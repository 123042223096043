export default class AppUrl {
    // --- PAGE
    public static readonly PAGE_ADMINS = '/admins';
    public static readonly PAGE_ACCOUNT = '/account';
    public static readonly PAGE_AUDIT_LOG = '/audit-log';
    public static readonly PAGE_CUSTOMERS = '/customers';
    public static readonly PAGE_DASHBOARD = '/dashboard';
    public static readonly PAGE_EVENTS = '/events';
    public static readonly PAGE_EVENT_GROUPS = '/event-groups';
    public static readonly PAGE_EVENT_LOGS = '/event-logs';
    public static readonly PAGE_HOME = '/';
    public static readonly PAGE_LEADS = '/leads';
    public static readonly PAGE_LEADS_ASSIGNMENT = '/leads/assignment';
    public static readonly PAGE_LEADS_LISTING = '/leads/listing';
    public static readonly PAGE_COMMISSIONS = '/commissions';
    public static readonly PAGE_COMMISSIONS_SIMULATION = '/commissions/simulation';
    public static readonly PAGE_LOGIN = '/login';
    public static readonly PAGE_ORDERS = '/orders';
    public static readonly PAGE_ORGANIZATION = '/organization';
    public static readonly PAGE_FORGOT_PASSWORDS = '/account/forgotpassword';
    public static readonly API_HEALTH_PING = '/up';

    // --- PAGE WITH PARAMS
    public static PAGE_CUSTOMERS_ID(customerUuid: string) {
        return AppUrl.PAGE_CUSTOMERS + '/' + customerUuid
    }

    public static PAGE_EVENTS_ID(eventId: number | string) {
        return '/events/' + eventId;
    }

    public static PAGE_EVENT_GROUPS_EID(eid: string) {
        return '/event-groups/' + eid;
    }

    public static PAGE_EVENTS_BUYERS(id: number) {
        return `/events/${id}/buyers`;
    }

    // --- API, sorted alphabetically
    public static readonly API_ADMINS_USER_INDEX = "/v1/users/admins"
    public static readonly API_ADMINS_PARAMS = "/v1/users/admins/params"
    public static readonly API_AUDIT_LOG = '/v1/audit-logs'
    public static readonly API_COMPANIES_ADMIN = '/v1/companies/admins'
    public static readonly API_CREATE_ATTENDANCE_FROM_ORDER = "/v1/attendance/order"
    public static readonly API_BUYERS_ASSIGN_TO_SALES_ADVISOR = "/v1/buyers/assign-to-sales-advisor"
    public static readonly API_BUYERS_PARAMS = "/v1/buyers"
    public static readonly API_COMPANIES_PARAMS = "/v1/companies/params"
    public static readonly API_CUSTOMERS_ASSIGN_TO_SALES_ADVISOR = "/v1/customers/assign-to-sales-advisor"
    public static readonly API_CUSTOMERS_INDEX = "/v1/customers"
    public static readonly API_CUSTOMERS_PARAMS = "/v1/customers/params"
    public static readonly API_EVENTS_INDEX = "/v1/events"
    public static readonly API_EVENT_GROUPS_INDEX = "/v1/event-groups"
    public static readonly API_EVENT_GROUPS_SEARCH = "/v1/event-groups/search"
    public static readonly API_EVENTS_DUPLICATE = "/v1/events/duplicate"
    public static readonly API_EVENTS_PARAMS = "/v1/events/params"
    public static readonly API_EVENTS_SEARCH = "/v1/events/search"
    public static readonly API_LEADS_INDEX = "/v1/leads"
    public static readonly API_LEADS_ASSIGN_TO_SALES_ADVISOR = "/v1/leads/assign-to-sales-advisor"
    public static readonly API_LEADS_DATA = "/v1/leads/data"
    public static readonly API_LEADS_PARAMS = "/v1/leads/params"
    public static readonly API_ORDERS_BUYERS_INDEX = "/v1/orders/buyers"
    public static readonly API_ORDERS_BULK_RECEIPT_UPDATE = "/v1/orders/bulk-receipt-update"
    public static readonly API_ORDERS_BULK_IMPORT = "/v1/orders/bulk-import"
    public static readonly API_ORDERS_BULK_INVOICE_UPDATE = "v1/orders/bulk-invoice-update"
    public static readonly API_ORDERS_DATA = "/v1/orders/data"
    public static readonly API_ORDERS_INDEX = "/v1/orders"
    public static readonly API_ORDERS_PARAMS = "/v1/orders/params"
    public static readonly API_ORDERS_UPDATE_REFERRER = "/v1/super-admin/update-referrer"
    public static readonly API_ORGANIZATIONS_PROFILE = "/v1/organizations/profile"
    public static readonly API_PASSWORD_FORGOT = "/v1/forgot-password"
    public static readonly API_PASSWORD_RESET = "/v1/reset-password"
    public static readonly API_USER_ACTIVITIES_PARAMS = "/v1/customers/activities"
    public static readonly API_USER_FETCH = "/v1/admin/account"
    public static readonly API_USER_INFO = "/v1/admin/account/info"
    public static readonly API_USER_PASSWORD = "/v1/admin/account/password"
    public static readonly API_USER_CONFIG_EVENT_BUYERS = '/v1/users/admins/config/events/buyers-export';
    public static readonly API_UPLOAD_ORDER_DOC = '/v1/upload/order/doc'

    // --- API WITH PARAMS
    public static API_BUYERS_ATTEND(id: number | string) {
        return `/v1/buyers/${id}/attendance`;
    }

    public static API_EVENTS_ATTENDANCE(id: number | string) {
        return `/v1/events/${id}/attendance`;
    }

    public static API_EVENTS_BUYERS(id: number | string) {
        return `/v1/events/${id}/buyers`;
    }


    public static API_EVENTS_BUYERS_DATA(id: number | string) {
        return `/v1/events/${id}/buyers/data`;
    }

    public static API_EVENTS_BUYERS_DATA_WITH_PAST_ORDER(id: number | string) {
        return `/v1/events/${id}/buyers/past-orders/data`;
    }

    public static API_EVENTS_BUYERS_SALES_ADVISOR(id: number | string) {
        return `v1/buyers/${id}/unassign-sales-advisor`;
    }

    public static API_EVENT_GROUPS_ID(id: string) {
        return `/v1/event-groups/${id}`;
    }

    public static API_EVENTS_OPTION(id: number | string) {
        return `/v1/events/option/${id}`;
    }

    public static API_EVENTS_PARAMS_OPTIONS(id: number | string) {
        return `/v1/events/${id}/params/event-options`;
    }

    public static API_EVENTS_PARAMS_OPTIONS_WITH_COUNT(id: number | string) {
        return `/v1/events/${id}/params/event-options-with-count`;
    }

    public static API_UPLOAD_EVENT_EDITOR_IMAGE(eventId: number | string) {
        return "/v1/upload/event/" + eventId + "/editor-image"
    }

    public static API_UPLOAD_EVENT_FEATURED_IMAGE(eventId: number | string) {
        return "/v1/upload/event/" + eventId + "/featured-image"
    }

    public static API_UPLOAD_ORDER_ID_DOC(orderId: number | string) {
        return `/v1/upload/order/${orderId}/doc`
    }

    public static API_EVENTS_SUMMARY(id: number | string) {
        return `/v1/events/${id}/summary`;
    }

    public static API_EVENTS_SUMMARY_BY_REFERERRS(id: number | string) {
        return `/v1/events/${id}/buyers/referrers`;
    }

    public static API_EVENTS_SUMMARY_BY_SOURCES(id: number | string) {
        return `/v1/events/${id}/buyers/sources`;
    }

    public static API_ORDERS_ATTACHMENT_VIEW(id: number | string) {
        return `v1/orders/${id}/attachment/view`;
    }

    public static API_PAYMENT_PARAMS(id: number | string) {
        return `v1/orders/${id}/payment-url`;
    }

    public static API_REFERRERS_NAME(name: string) {
        return `/v1/referrers/name?name=${name}`;
    }

    public static API_DOWNLOAD_ORDER(trackingNum: string) {
        return useRuntimeConfig().public.apiUrl + '/fs/v1/api/order/download/' + trackingNum
    }
    public static API_EVENT_OWNER(productEid: number | string) {
        return `/v1/events/${productEid}/owner`;
    }
    public static API_EVENTS_SOCIAL_MEDIA(productId: number | string) {
        return `/v1/events/${productId}/social-media`;
    }
}
