<script setup lang='ts'>
///////////////////////////////////////////////@  Import, Types & meta
//////////////////////////////////////////////////////////////////////
import { Icon } from '@iconify/vue';
import IwFormUploaderConfig from '../utils/IwFormUploaderConfig'

//////////////////////////////////////////////////////@  Props & Emits
//////////////////////////////////////////////////////////////////////

const emit = defineEmits<{
    (e: 'change', fileOrUrl?: File | string | null): void,
}>()

const props = defineProps({
    id: {
        type: String,
        default: 'iwFormUploader'
    },
    config: {
        type: Object as PropType<IwFormUploaderConfig>,
        required: true
    },
})

const fileInputElem = ref<HTMLInputElement>()
const _imageSrc = ref(props.config.imageSrc)
let userOnImageLoaded: Function
let userOnUploadedFunc: Function

//////////////////////////////////////////////////////////@  Variables
//////////////////////////////////////////////////////////////////////

const closeBtnIcon = 'solar:trash-bin-minimalistic-bold'

/////////////////////////////////////////////////@  Computed & Watches
//////////////////////////////////////////////////////////////////////

const uploadedFileList = computed(() => {
    const fileList = props.config.getFiles()
    return fileList && fileList.length ? fileList : []
})

//////////////////////////////////////////////////////////@  Functions
//////////////////////////////////////////////////////////////////////
function clearFiles() {
    if (fileInputElem.value) {
        fileInputElem.value.value = ''
    }
    _imageSrc.value = ''

    props.config._clearFileList()
    emit('change', null)
}

function init() {
    if ('image' == props.config.type) {
        overrideCallerOnImageLoaded()
        overrideCallerOnUploaded()
    }
}

function onChange(ev: Event) {
    try {
        if (props.config.onUploadFileChange) {
            props.config.onUploadFileChange(ev)
        } else {
            props.config._onUploadFileChange(ev)
        }
    } catch (err) {
        // Reset file list to remove problematic file
        if (fileInputElem.value) {
            fileInputElem.value.value = ''
        }

        // Bubble up error
        throw err
    }

    // TODO: Properly encapsulate file with `FormData` when POST
    const file = (ev.target as HTMLInputElement | null)?.files?.[0]

    // TODO: An extra 'change' emit will be sent if `uploadFunc` also sends one
    //       in onUploadeded
    // This emit will still be needed if uploadFunc is used but does not return
    // any values
    emit('change', file)
}

function onDragOver(ev: Event) {
    if (props.config.onDragOver) {
        props.config.onDragOver(ev)
    }
    // NOTE: `emit` is not required as `onDragOver` doesn't return any files
}

function onDrop(ev: DragEvent) {
    if (props.config.onDrop) {
        props.config.onDrop(ev)
    } else {
        props.config._onDrop(ev)
    }

    const file = (ev.target as HTMLInputElement | null)?.files?.[0]

    // TODO: An extra 'change' emit will be sent if `uploadFunc` also sends one
    //       in onUploadeded
    // This emit will still be needed if uploadFunc is used but does not return
    // any values
    emit('change', file)
}

function onImageError() {
    // Reset file list
    if (fileInputElem.value) {
        fileInputElem.value.value = ''
    }
    // Reset image preview
    _imageSrc.value = ''

    throw new Error('Invalid image data.')
}

function onImageLoaded(src: string) {
    _imageSrc.value = src

    if (userOnImageLoaded) {
        userOnImageLoaded(src)
    }
}

async function onRemoveFile() {
    if (props.config.onRemoveFile) {
        const allowFileRemoval = await props.config.onRemoveFile()
        if (allowFileRemoval) {
            clearFiles()
        }
    } else {
        clearFiles()
    }
}

function onUploaded(res: UploadResponse | string) {
    if (res) {
        const src = typeof res === 'string' ? res : res.url
        _imageSrc.value = src

        emit('change', src)
    }

    if (userOnUploadedFunc) {
        userOnUploadedFunc(res)
    }

}

function overrideCallerOnImageLoaded() {
    if (typeof props.config.onImageLoaded == 'function') {
        userOnImageLoaded = props.config.onImageLoaded
    }

    props.config.onImageLoaded = onImageLoaded
}

function overrideCallerOnUploaded() {
    if (typeof props.config.onUploaded == 'function') {
        userOnUploadedFunc = props.config.onUploaded
    }

    props.config.onUploaded = onUploaded
}
/////////////////////////////////////////////////////////@  Lifecycles
//////////////////////////////////////////////////////////////////////
init()

//////////////////////////////////////////////////////@ Initialization
//////////////////////////////////////////////////////////////////////


////////////////////////////////////////////////////@  Export & Expose
//////////////////////////////////////////////////////////////////////
</script>

<template>
    <div v-if="config"
         class="iwFormUploadWrapper"
         :class="props.config.isUploading ? 'iwFormUploadWrapperLoading' : ''">
        <section>
            <div v-if="config.sampleDownloadLink"
                 class="text-right my-2">
                <a :href="config.sampleDownloadLink"
                   class="cursor-pointer text-blue-600 underline dark:text-blue-300">
                    {{ config.sampleDownloadLinkLabel }}
                </a>
            </div>

            <!-- Loading icon during upload -->
            <div v-if="props.config.isUploading"
                 class="iwFormUploadLoadingIconWrapper">
                <Icon class="iwFormUploadLoadingIcon"
                      icon="mingcute:loading-fill" />
            </div>

            <!-- File preview -->
            <div v-if="_imageSrc">
                <img class="mx-auto"
                     :class="config.maxHeight"
                     :src="props.config?.generateImgPreview?.(_imageSrc) ?? _imageSrc"
                     @error="onImageError">
                <Icon :icon="closeBtnIcon"
                      class="iwFormUploadRemoveFileBtn"
                      @click="onRemoveFile" />
            </div>
            <div v-else-if="uploadedFileList.length"
                 class="iwFormUploadLabel hasFile">
                <div class="iwFormUploadIconWrapper">
                    <Icon class="iwFormUploadIcon hasFile"
                          icon="line-md:document-list" />
                    <p class="iwFormUploadLabelText hasFile">
                        <!-- NOTE: Currently only display one file -->
                        {{ uploadedFileList[0].name }}
                    </p>
                </div>
                <Icon :icon="closeBtnIcon"
                      class="iwFormUploadRemoveFileBtn"
                      @click="onRemoveFile" />
            </div>
            <label v-else
                   :for="id"
                   @dragover.prevent="onDragOver"
                   @drop.prevent="onDrop"
                   class="iwFormUploadLabel">
                <div class="iwFormUploadIconWrapper">
                    <Icon :icon="config.icon"
                          class="iwFormUploadIcon" />
                    <p class="iwFormUploadLabelText">{{ config.label }}
                    </p>
                </div>
                <input :id="id"
                       ref="fileInputElem"
                       type="file"
                       :accept="config.getAcceptedFileTypes()"
                       class="hidden"
                       @change="onChange" />
            </label>
        </section>
    </div>
</template>

<style scoped>
</style>
