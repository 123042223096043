import Compressor from 'compressorjs'

export const useResizeImage = () => {
    /***
     *  @param file The image file to compress
     *  @param maxImageSizeInMb  Max image size in MB that allow to upload
     *  @param containerWidth The max-width of the image container
     *  @param options An object containing the types of files and their handling options
     */
    async function compressImage(
        file: File,
        maxImageSizeInMb: number,
        containerWidth?: number,
        options?: { compressibleTypes?: string[], nonCompressibleTypes?: string[] }
    ): Promise<Blob> {
        const maxImageSizeInBytes = maxImageSizeInMb * 1024 * 1024

        const defaultOptions = {
            compressibleTypes: ['image/png', 'image/webp'],
            nonCompressibleTypes: ['image/apng', 'image/gif'],
        }

        const imageFileTypes = {
            compressibleTypes: [...defaultOptions.compressibleTypes, ...(options?.compressibleTypes || [])],
            nonCompressibleTypes: [...defaultOptions.nonCompressibleTypes, ...(options?.nonCompressibleTypes || [])],
        }

        const { compressibleTypes, nonCompressibleTypes } = imageFileTypes

        const compressWithoutConversion = (file: File | Blob): Promise<Blob> => {
            return new Promise((resolve, reject) => {
                new Compressor(file, {
                    maxWidth: containerWidth,
                    quality: 0.8,
                    convertSize: Infinity,
                    success(result) {
                        resolve(result)
                    },
                    error(err) {
                        reject(err)
                    },
                })
            })
        }

        const compressToJPEG = (file: File | Blob): Promise<Blob> => {
            return new Promise((resolve, reject) => {
                new Compressor(file, {
                    maxWidth: containerWidth,
                    quality: 0.8,
                    // Files whose file type is included in the convertTypes
                    // list, and whose file size exceeds convertSize would be
                    // converted to JPEGs.
                    convertTypes: file.type,
                    convertSize: maxImageSizeInMb,
                    success(result) {
                        if (result.size < maxImageSizeInBytes) {
                            resolve(result)
                        } else {
                            reject(Error('The uploaded file is still too large after being compressed.'))
                        }
                    },
                    error(err) {
                        reject(err)
                    },
                })
            })
        }

        if (file.size < maxImageSizeInBytes) {
            return file
        }

        if (nonCompressibleTypes.includes(file.type)) {
            throw new Error('Failed to compress the uploaded file. File must be smaller than ' + maxImageSizeInMb + 'MB.')
        }

        if (compressibleTypes.includes(file.type)) {
            let compressedFile: Blob
            compressedFile = await compressWithoutConversion(file)

            if (compressedFile.size < maxImageSizeInBytes) {
                return compressedFile
            }
        }

        return compressToJPEG(file)
    }
    return { compressImage }
}

export default useResizeImage
