/** @unocss-include */
import { ref } from 'vue'
import IwObject from '../utils/IwObject';
import IwFormConfig from '../utils/IwFormConfig';
export function setLabel(item: IwFormInputCore) {
    let label = typeof item.label === 'string' ? item.label : item.label?.text
    if (undefined === label || null === label) label = item.name

    return label.charAt(0).toUpperCase() + label.slice(1)
}

export function setRequired(formData: Record<string, any>, item: IwFormInputCore) {
    if (typeof item.required == 'function') return item.required(formData)
    if (item.required) return true
    return false
}


export const useIwForm = (config: IwFormUseConfig) => {
    let totalSubmission = 0;
    // let canSubmitAgain = config.canSubmitAgain;
    // let dialogIsOpen = false;

    // refs
    const myFormData = ref({})
    const errors = ref({})
    const formErrorMsg = ref('')
    const inputRefs = ref<Record<string, Element | ComponentPublicInstance | null>>({})
    const keys = ref<string[]>([])
    const submitIsLoading = ref<boolean>(false)
    const funcToastError = config.funcToastError

    const myForm: IwFormConfig = config.myForm
    myForm.formGroups.forEach(group => {
        group.formInputs.forEach(formInput => {
            const name = (formInput as IwFormInputCore).name
            if (name) {
                keys.value[name] = `${name}-${Date.now()}-${Math.random() * 10000}`
            }
        })
    })

    // ------------------------------------------------------

    function clearErrorsIfValidated(item: IwFormInputCore, val: any) {
        if (errors.value[item.name]) {
            if (validate(item, val)) {
                delete errors.value[item.name]
            }
        }
    }

    function getCss(item: IwFormInput, param?: { cssArray?: string[], cssObj?: Record<string, boolean>, default?: string }) {
        let extraCss: string = ''

        const isVisible = (item as IwFormInputCore).isVisible
        if (typeof isVisible === 'function') {
            const isItemVisible = isVisible(myFormData)
            if (false === isItemVisible) {
                extraCss += ' hidden'
            } else {
                extraCss += ' ' + isItemVisible
            }
        } else if (false === isVisible) {
            extraCss += ' hidden'
        }

        if (param) {
            if (param.cssArray) {
                for (const css of param.cssArray) {
                    extraCss += ` ${css}`
                }
            }

            if (param.cssObj) {
                for (const [key, isTrue] of Object.entries(param.cssObj)) {
                    if (isTrue) extraCss += ` ${key} `
                }
            }

            if (!extraCss && param.default) extraCss = param.default
        }

        return `${extraCss}`
    }

    function formOnReset() {
        let resetIgnored: string[];
        if (typeof config.resetIgnored === 'function') {
            resetIgnored = config.resetIgnored()
        } else {
            resetIgnored = []
        }

        // trigger input component rest function
        Object.values(inputRefs.value).forEach(function (theInput: any) {
            if (theInput.onReset) theInput.onReset()
        });

        // reset data
        new IwObject(myFormData.value).reset(resetIgnored);
        for (const key of Object.keys(myFormData.value)) {
            myFormData.value[key] = null
        }

        // setCanSubmitAgain(false);
        if (config.onReset) config.onReset()
    }

    async function formOnSubmit(ev: Event) {
        if (!validateAll()) return

        if (totalSubmission == 0) {
            try {
                if (config.onSubmit) {
                    // clear previous error if any
                    formErrorMsg.value = ''

                    // prepare the data
                    const data = removeDisabledInputValue()
                    await config.onSubmit(data)
                    if (config.myForm.onSuccess) config.myForm.onSuccess()
                }
            } catch (err: any) {
                console.error(err)
                formErrorMsg.value = err.message
                // TODO:
                if (config.myForm.onError) config.myForm.onError(err.message)
                if (config.myForm.rethrowErrorOnSubmit) throw err
            }
            // Prompt Dialog
            // dialogIsOpen = true;
            // } else if (canSubmitAgain) {
            //   submitConfirmed();
        }
    }

    function getAriaLabel(item: IwFormInputCore): string {
        if (item.disabled) return 'Input disabled';
        return 'form input'
    }

    function getFormData() {
        return myFormData.value;
    }

    function getFormGroupSubmitLabel(group: IwFormGroup, defaultLabel: string) {
        const submitBtn = group.submitBtn
        if (submitBtn) {
            if (submitBtn.label) {
                return submitBtn.label
            }
        }

        return defaultLabel
    }

    function getInputCss(item: IwFormInputCore, defaultCss?: string): string {
        let css;
        if (item.disabled) {
            css = 'iwFormInputDisabled'
        } else {
            css = defaultCss ?? 'iwFormInputText'
        }

        if (item.showPrefixIcon) css += ' iwFormPrefixIconPadding'

        if (item.cssInput) css = css + ' ' + item.cssInput

        return css
    }

    function getVisibility(item: IwFormInputCore) {
        if (typeof item.isVisible === 'boolean') return item.isVisible
        if (typeof item.isVisible === 'function') return item.isVisible(item, myFormData)

        return true
    }

    function isDisabled(disabled: boolean | undefined, isReadOnly: boolean) {
        return disabled || isReadOnly
    }

    function initFormData() {
        if (config.myForm.formData && Object.keys(config.myForm.formData).length >= 1) {
            // Reference original formData
            myFormData.value = config.myForm.formData
        }

        // set value if inputX.value is defined, and myFormData is yet to be defined for inputX
        for (const group of (config.myForm.formGroups)) {
            group.formInputs.forEach((item: IwFormInput) => {
                if (!('name' in item)) {
                    return
                }

                const name = (item as IwFormInputCore).name
                if (name && (null === config.myForm.formData[name])) {
                    const val = (item as IwFormInputCore).value
                    if (val !== 'undefined') {
                        myFormData.value[name] = val
                    }
                } else if (name && (null === item.value || undefined === item.value)) {
                    item.value = myFormData.value[name]
                }
            })
        }
    }

    function initRenderCallback() {
        myForm.renderCallBack = (name: string) => {
            validateAll(false)
            keys.value[name] = `${name}-${Date.now()}-${Math.random() * 10000}`
            myFormData.value[name] = null
        }
    }

    function isVisible(item: IwFormInputCore): boolean {
        if (null != item.visibleOnData) {
            return isVisibleOnData(item)
        }
        return getVisibility(item)
    }

    function isVisibleOnData(item: IwFormInputCore): boolean {
        if (item.visibleOnData) {
            for (const dataKey of item.visibleOnData) {
                const val = myFormData.value[dataKey]
                if (!val || 0 == val) {
                    return false
                }
            }
            return true
        }

        return false
    }

    function onBlur(item: IwFormInputCore, val: any) {
        if (validate(item, val)) {
            delete errors.value[item.name]
        }

        if (typeof item.onBlur == 'function') {
            return item.onBlur(myFormData);
        }
    }

    async function onChange(item: IwFormInputCore, val: any, ...extra: any[]) {
        const key = item.name

        item.value = val
        myFormData.value[key] = val
        clearErrorsIfValidated(item, val)

        if (item.onChange) item.onChange(myForm, item, val, ...extra)
        if (item.onChangeUpdateInput) {
            const res = await item.onChangeUpdateInput(myForm, item, val, ...extra)
            myForm.updateSelectInput(res.linkedInputName, res.newSelectConfig)
        }
    }

    function onFocus(item: IwFormInput, data: any) {
        // validate(item, data)
    }

    function onInput(item: IwFormInputCore, val: any) {
        const key = item.name
        myFormData.value[key] = val
        clearErrorsIfValidated(item, val)
    }


    /**
     * Event handler for select dropdown search
     *
     * @param item The IwFormInput object
     * @param val  The search query
     */
    async function onSelectSearch(item: IwFormInputSelectCore, val: any) {
        if (item.onSearch) {
            item.onSearch.call(myForm, item, val)
        } else if (item.onSearchUpdateInput) {

            const theInput = myForm.getInputByName(item.name)
            if (theInput) {
                theInput.selectConfig.setShowLoading();
                const res = await item.onSearchUpdateInput(myForm, item, val)
            }
        }
    }

    function removeDisabledInputValue(): Record<string, any> {
        // Shallow clone to formData
        const formData = Object.assign({}, myFormData.value)

        for (const group of config.myForm.formGroups) {
            group.formInputs.forEach((item: IwFormInput) => {
                if (!('name' in item)) {
                    return
                }

                if (
                    item.shouldDehydrate === false
                    || (item.disabled && true !== item.shouldDehydrate)
                ) {
                    const name = item.name
                    delete formData[name]
                }
            })
        }

        return formData
    }



    function validate(item: IwFormInputCore, data: any, showError = true) {
        // Trim `data` before checking if data is empty
        if (typeof data === 'string') {
            data = data.trim()
        }
        const isDataEmpty = data == null || data === ''

        // Skip empty-value checking
        if (item.rules && !isDataEmpty) {
            for (const rule of item.rules) {
                const param: IRuleData = { value: data, myFormData: myFormData.value }
                const err = rule(param)
                if (typeof err === 'string') {
                    if (showError) {
                        errors.value[item.name] = err
                    }
                    return false
                }
            }
        }
        if (item.required) {
            if (isDataEmpty) {
                if (showError) {
                    errors.value[item.name] = 'Required'
                }
                return false
            }
        } else {
            if (errors.value[item.name]) {
                delete errors.value[item.name]
            }
        }
        return true
    }

    function validateAll(showError = true): boolean {
        let hasError = false
        let firstInvalidElement: HTMLElement | null = null

        for (const group of (config.myForm.formGroups)) {
            for (const item of group.formInputs) {
                if ('name' in item && !validate(item, myFormData.value[item.name], showError)) {
                    const labelText = typeof item.label === 'object'
                        ? item.label.text
                        : item.label
                    const msg = (labelText ? labelText : item.name) + ': ' + errors.value[item.name]

                    if (showError) {
                        if (typeof myForm.onError == 'function') {
                            myForm.onError(msg)
                        }
                        if (typeof funcToastError == 'function') {
                            funcToastError(msg)
                        }
                        const inputRef = inputRefs.value[item.name]
                        if (!firstInvalidElement && inputRef) {
                            firstInvalidElement = '$el' in inputRef ? inputRef.$el : inputRef
                        }
                    }

                    hasError = true
                }
            }
        }
        if (hasError && firstInvalidElement) {
            /**
             * Tabindex allows HTML element to receive focus.
             * Zero or positive tab index allow element to be navigated through
             * the keyboard's Tab key.
             */
            if (!firstInvalidElement.tabIndex) {
                firstInvalidElement.tabIndex = 0
            }
            firstInvalidElement.focus()
        }
        return !hasError
    }

    return {
        // variables
        errors,
        formErrorMsg,
        inputRefs,
        keys,
        myFormData,
        submitIsLoading,
        totalSubmission,

        // functions
        clearErrorsIfValidated,
        formOnReset,
        formOnSubmit,
        getAriaLabel,
        getCss,
        getFormData,
        getFormGroupSubmitLabel,
        getInputCss,
        getVisibility,
        initFormData,
        initRenderCallback,
        isDisabled,
        isVisible,
        onBlur,
        onChange,
        onFocus,
        onInput,
        onSelectSearch,
        removeDisabledInputValue,
        validate,
        validateAll,
    }

}

export default useIwForm
