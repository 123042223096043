import { default as forgotPasswordyAQgOa4yWMMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/account/forgotPassword.vue?macro=true";
import { default as indexGenGvERRayMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/account/index.vue?macro=true";
import { default as _91id_93NEnmsvc13XMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/admins/[id].vue?macro=true";
import { default as indexVCjYcI9whzMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/admins/index.vue?macro=true";
import { default as indexjgFMZqVirAMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/audit-log/index.vue?macro=true";
import { default as index4E1HqOC3FHMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/commissions/index.vue?macro=true";
import { default as simulationZxbXVqUKK9Meta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/commissions/simulation.vue?macro=true";
import { default as _91id_93vJDxW6dd7BMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/customers/[id].vue?macro=true";
import { default as indexR1vDkFJjEwMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/customers/index.vue?macro=true";
import { default as dashboardQMTeSRdxgFMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/dashboard.vue?macro=true";
import { default as indexsFeHynsdvvMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/event-groups/[eid]/index.vue?macro=true";
import { default as indexTXoCuGPNFBMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/event-groups/index.vue?macro=true";
import { default as indexYShS5uF0MiMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/event-logs/index.vue?macro=true";
import { default as buyerswid2VTrG6bMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/events/[id]/buyers.vue?macro=true";
import { default as indexnHpjamz28aMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/events/[id]/index.vue?macro=true";
import { default as indexPb1PKMp6zvMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/events/index.vue?macro=true";
import { default as newtCQBsaWk5dMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/events/new.vue?macro=true";
import { default as healthtORalWG5SJMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/health.vue?macro=true";
import { default as indexhYJBUhs0DoMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/index.vue?macro=true";
import { default as _91id_93HmHT8eWwXoMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/leads/[id].vue?macro=true";
import { default as index9JArEO17JwMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/leads/index.vue?macro=true";
import { default as new4AlyvvDkUQMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/leads/new.vue?macro=true";
import { default as loginixOkjGty8aMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/login.vue?macro=true";
import { default as indexvLjLpQUi1jMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/orders/index.vue?macro=true";
import { default as indextu2XP4GMUVMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/organization/index.vue?macro=true";
import { default as _91token_93BQGVQASMXPMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/password-reset/[token].vue?macro=true";
export default [
  {
    name: forgotPasswordyAQgOa4yWMMeta?.name ?? "account-forgotPassword",
    path: forgotPasswordyAQgOa4yWMMeta?.path ?? "/account/forgotPassword",
    meta: forgotPasswordyAQgOa4yWMMeta || {},
    alias: forgotPasswordyAQgOa4yWMMeta?.alias || [],
    redirect: forgotPasswordyAQgOa4yWMMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/account/forgotPassword.vue").then(m => m.default || m)
  },
  {
    name: indexGenGvERRayMeta?.name ?? "account",
    path: indexGenGvERRayMeta?.path ?? "/account",
    meta: indexGenGvERRayMeta || {},
    alias: indexGenGvERRayMeta?.alias || [],
    redirect: indexGenGvERRayMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93NEnmsvc13XMeta?.name ?? "admins-id",
    path: _91id_93NEnmsvc13XMeta?.path ?? "/admins/:id()",
    meta: _91id_93NEnmsvc13XMeta || {},
    alias: _91id_93NEnmsvc13XMeta?.alias || [],
    redirect: _91id_93NEnmsvc13XMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/admins/[id].vue").then(m => m.default || m)
  },
  {
    name: indexVCjYcI9whzMeta?.name ?? "admins",
    path: indexVCjYcI9whzMeta?.path ?? "/admins",
    meta: indexVCjYcI9whzMeta || {},
    alias: indexVCjYcI9whzMeta?.alias || [],
    redirect: indexVCjYcI9whzMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/admins/index.vue").then(m => m.default || m)
  },
  {
    name: indexjgFMZqVirAMeta?.name ?? "audit-log",
    path: indexjgFMZqVirAMeta?.path ?? "/audit-log",
    meta: indexjgFMZqVirAMeta || {},
    alias: indexjgFMZqVirAMeta?.alias || [],
    redirect: indexjgFMZqVirAMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/audit-log/index.vue").then(m => m.default || m)
  },
  {
    name: index4E1HqOC3FHMeta?.name ?? "commissions",
    path: index4E1HqOC3FHMeta?.path ?? "/commissions",
    meta: index4E1HqOC3FHMeta || {},
    alias: index4E1HqOC3FHMeta?.alias || [],
    redirect: index4E1HqOC3FHMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/commissions/index.vue").then(m => m.default || m)
  },
  {
    name: simulationZxbXVqUKK9Meta?.name ?? "commissions-simulation",
    path: simulationZxbXVqUKK9Meta?.path ?? "/commissions/simulation",
    meta: simulationZxbXVqUKK9Meta || {},
    alias: simulationZxbXVqUKK9Meta?.alias || [],
    redirect: simulationZxbXVqUKK9Meta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/commissions/simulation.vue").then(m => m.default || m)
  },
  {
    name: _91id_93vJDxW6dd7BMeta?.name ?? "customers-id",
    path: _91id_93vJDxW6dd7BMeta?.path ?? "/customers/:id()",
    meta: _91id_93vJDxW6dd7BMeta || {},
    alias: _91id_93vJDxW6dd7BMeta?.alias || [],
    redirect: _91id_93vJDxW6dd7BMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/customers/[id].vue").then(m => m.default || m)
  },
  {
    name: indexR1vDkFJjEwMeta?.name ?? "customers",
    path: indexR1vDkFJjEwMeta?.path ?? "/customers",
    meta: indexR1vDkFJjEwMeta || {},
    alias: indexR1vDkFJjEwMeta?.alias || [],
    redirect: indexR1vDkFJjEwMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: dashboardQMTeSRdxgFMeta?.name ?? "dashboard",
    path: dashboardQMTeSRdxgFMeta?.path ?? "/dashboard",
    meta: dashboardQMTeSRdxgFMeta || {},
    alias: dashboardQMTeSRdxgFMeta?.alias || [],
    redirect: dashboardQMTeSRdxgFMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexsFeHynsdvvMeta?.name ?? "event-groups-eid",
    path: indexsFeHynsdvvMeta?.path ?? "/event-groups/:eid()",
    meta: indexsFeHynsdvvMeta || {},
    alias: indexsFeHynsdvvMeta?.alias || [],
    redirect: indexsFeHynsdvvMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/event-groups/[eid]/index.vue").then(m => m.default || m)
  },
  {
    name: indexTXoCuGPNFBMeta?.name ?? "event-groups",
    path: indexTXoCuGPNFBMeta?.path ?? "/event-groups",
    meta: indexTXoCuGPNFBMeta || {},
    alias: indexTXoCuGPNFBMeta?.alias || [],
    redirect: indexTXoCuGPNFBMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/event-groups/index.vue").then(m => m.default || m)
  },
  {
    name: indexYShS5uF0MiMeta?.name ?? "event-logs",
    path: indexYShS5uF0MiMeta?.path ?? "/event-logs",
    meta: indexYShS5uF0MiMeta || {},
    alias: indexYShS5uF0MiMeta?.alias || [],
    redirect: indexYShS5uF0MiMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/event-logs/index.vue").then(m => m.default || m)
  },
  {
    name: buyerswid2VTrG6bMeta?.name ?? "events-id-buyers",
    path: buyerswid2VTrG6bMeta?.path ?? "/events/:id()/buyers",
    meta: buyerswid2VTrG6bMeta || {},
    alias: buyerswid2VTrG6bMeta?.alias || [],
    redirect: buyerswid2VTrG6bMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/events/[id]/buyers.vue").then(m => m.default || m)
  },
  {
    name: indexnHpjamz28aMeta?.name ?? "events-id",
    path: indexnHpjamz28aMeta?.path ?? "/events/:id()",
    meta: indexnHpjamz28aMeta || {},
    alias: indexnHpjamz28aMeta?.alias || [],
    redirect: indexnHpjamz28aMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/events/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexPb1PKMp6zvMeta?.name ?? "events",
    path: indexPb1PKMp6zvMeta?.path ?? "/events",
    meta: indexPb1PKMp6zvMeta || {},
    alias: indexPb1PKMp6zvMeta?.alias || [],
    redirect: indexPb1PKMp6zvMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: newtCQBsaWk5dMeta?.name ?? "events-new",
    path: newtCQBsaWk5dMeta?.path ?? "/events/new",
    meta: newtCQBsaWk5dMeta || {},
    alias: newtCQBsaWk5dMeta?.alias || [],
    redirect: newtCQBsaWk5dMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/events/new.vue").then(m => m.default || m)
  },
  {
    name: healthtORalWG5SJMeta?.name ?? "health",
    path: healthtORalWG5SJMeta?.path ?? "/health",
    meta: healthtORalWG5SJMeta || {},
    alias: healthtORalWG5SJMeta?.alias || [],
    redirect: healthtORalWG5SJMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/health.vue").then(m => m.default || m)
  },
  {
    name: indexhYJBUhs0DoMeta?.name ?? "index",
    path: indexhYJBUhs0DoMeta?.path ?? "/",
    meta: indexhYJBUhs0DoMeta || {},
    alias: indexhYJBUhs0DoMeta?.alias || [],
    redirect: indexhYJBUhs0DoMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93HmHT8eWwXoMeta?.name ?? "leads-id",
    path: _91id_93HmHT8eWwXoMeta?.path ?? "/leads/:id()",
    meta: _91id_93HmHT8eWwXoMeta || {},
    alias: _91id_93HmHT8eWwXoMeta?.alias || [],
    redirect: _91id_93HmHT8eWwXoMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/leads/[id].vue").then(m => m.default || m)
  },
  {
    name: index9JArEO17JwMeta?.name ?? "leads",
    path: index9JArEO17JwMeta?.path ?? "/leads",
    meta: index9JArEO17JwMeta || {},
    alias: index9JArEO17JwMeta?.alias || [],
    redirect: index9JArEO17JwMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/leads/index.vue").then(m => m.default || m)
  },
  {
    name: new4AlyvvDkUQMeta?.name ?? "leads-new",
    path: new4AlyvvDkUQMeta?.path ?? "/leads/new",
    meta: new4AlyvvDkUQMeta || {},
    alias: new4AlyvvDkUQMeta?.alias || [],
    redirect: new4AlyvvDkUQMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/leads/new.vue").then(m => m.default || m)
  },
  {
    name: loginixOkjGty8aMeta?.name ?? "login",
    path: loginixOkjGty8aMeta?.path ?? "/login",
    meta: loginixOkjGty8aMeta || {},
    alias: loginixOkjGty8aMeta?.alias || [],
    redirect: loginixOkjGty8aMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/login.vue").then(m => m.default || m)
  },
  {
    name: indexvLjLpQUi1jMeta?.name ?? "orders",
    path: indexvLjLpQUi1jMeta?.path ?? "/orders",
    meta: indexvLjLpQUi1jMeta || {},
    alias: indexvLjLpQUi1jMeta?.alias || [],
    redirect: indexvLjLpQUi1jMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indextu2XP4GMUVMeta?.name ?? "organization",
    path: indextu2XP4GMUVMeta?.path ?? "/organization",
    meta: indextu2XP4GMUVMeta || {},
    alias: indextu2XP4GMUVMeta?.alias || [],
    redirect: indextu2XP4GMUVMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/organization/index.vue").then(m => m.default || m)
  },
  {
    name: _91token_93BQGVQASMXPMeta?.name ?? "password-reset-token",
    path: _91token_93BQGVQASMXPMeta?.path ?? "/password-reset/:token()",
    meta: _91token_93BQGVQASMXPMeta || {},
    alias: _91token_93BQGVQASMXPMeta?.alias || [],
    redirect: _91token_93BQGVQASMXPMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin-2/pages/password-reset/[token].vue").then(m => m.default || m)
  }
]