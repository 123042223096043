<script setup lang='ts'>
///////////////////////////////////////////////@  Import, Types & meta
//////////////////////////////////////////////////////////////////////
import { Tippy } from 'vue-tippy'
import { Icon } from '@iconify/vue'
//////////////////////////////////////////////////////@  Props & Emits
//////////////////////////////////////////////////////////////////////
const props = defineProps({
    usageGuide: {
        type: Object as PropType<IwFormEditorUsageGuide>,
    },
})
//////////////////////////////////////////////////////////@  Variables
//////////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////@  Computed & Watches
//////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////@  Functions
//////////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////@  Lifecycles
//////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////@ Initialization
//////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////@  Export & Expose
//////////////////////////////////////////////////////////////////////
</script>

<template>
    <tippy v-if="props.usageGuide"
           class="iwFormEditorUsageGuide"
           :interactive="true"
           title="Click to toggle show or hide"
           trigger="click mouseenter">
        <template #default>
            <Icon icon="material-symbols:contact-support-rounded" />
        </template>

        <template #content>
            <div class="iwFormEditorUsageGuideContent">
                <b>{{ props.usageGuide.title }}</b>
                <p v-if="props.usageGuide.body">{{ props.usageGuide.body }}</p>
                <div class="iwFormEditorUsageGuideListSection">
                    <template v-for="list in props.usageGuide.lists ?? []">
                        <component :is="list.type == 'ordered' ? 'ol' : 'ul'">
                            <p>{{ list.label }}</p>
                            <li v-for="listItem in list.items">
                                {{ listItem }}
                            </li>
                        </component>
                    </template>
                </div>
                <footer v-if="props.usageGuide.footer">
                    <em>{{ props.usageGuide.footer }}</em>
                </footer>
            </div>
        </template>
    </tippy>
</template>
